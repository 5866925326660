import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

const SelectEscolaridad = ({ Gerente, escolaridadID, required }) => {
  const [escolaridades, setEscolaridades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme(); // Get the theme

  useEffect(() => {
    // Fetch escolaridades from the backend
    const fetchEscolaridades = async () => {
      try {
        const response = await axios.get("/Select/escolaridades"); // API endpoint
        setEscolaridades(response.data);
      } catch (error) {
        //console.error('Error fetching escolaridades:', error);
        setError("Error al cargar escolaridades."); // Set error message
      } finally {
        setLoading(false);
      }
    };

    fetchEscolaridades(); // Call the function when the component mounts
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={Boolean(errors.escolaridad)}
    >
      <InputLabel id="escolaridad-label" style={{ color: "#000000" }}>
        Escolaridad *
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Controller
          name="escolaridad"
          control={control}
          defaultValue={escolaridadID || ""}
          render={({ field }) => (
            <Select
            required={required}
              readOnly={Gerente}
              {...field}
              labelId="escolaridad-label"
              id="escolaridad"
              label="Escolaridad"
              style={{
                background: theme.palette.background.default, // Background color of the Select
                color: "#000000", // Text color
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.background.default, // Background color of the dropdown menu
                  },
                },
              }}
              renderValue={(selected) => (
                <span style={{ color: "#000000" }}>
                  {selected === ""
                    ? "Seleccione una opción"
                    : escolaridades.find(
                        (escolaridad) => escolaridad.ArticuloID === selected
                      )?.Nombre}
                </span>
              )}
            >
              <MenuItem value="" style={{ color: "#000000" }} disabled>
                Seleccione una opción
              </MenuItem>
              {escolaridades.length > 0 ? (
                escolaridades.map((escolaridad) => (
                  <MenuItem
                    key={escolaridad.ArticuloID}
                    value={escolaridad.ArticuloID}
                    style={{ color: "#000000" }}
                  >
                    {escolaridad.Nombre}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled style={{ color: "#000000" }}>
                  No hay escolaridades disponibles
                </MenuItem>
              )}
            </Select>
          )}
        />
      )}
      <FormHelperText>{errors.escolaridad?.message || ""}</FormHelperText>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectEscolaridad;
