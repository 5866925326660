import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import axios from "axios";

const MotivosSelect = ({ ServicioID, MotivoID, level, onChange, Admin }) => {
  const [motivos, setMotivos] = useState([]); // Inicializa como array vacío
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const fetchMotivos = async () => {
      try {
        setLoading(true);
        setError(null); // Reiniciar el error al intentar una nueva carga
        const response = await axios.get(
          `/clientes/Motivos/${ServicioID}/${level}`
        );

        //console.log(response.data);
        // Manejo de la respuesta
        if (response.status === null) {
          setMotivos([]); // Si la respuesta es null, establece motivos a un array vacío
        } else {
          setMotivos(response.data); // De lo contrario, establece los motivos
        }

        //console.log("Motivos", response.data);
      } catch (error) {
        setError("Error fetching Motivos. Please try again."); // Manejo de errores
        //console.error('Error fetching Motivos:', error);
      } finally {
        setLoading(false);
      }
    };

    if (ServicioID && level) {
      fetchMotivos(); // Solo ejecutar la petición si ServicioID y level están definidos
    }
    //console.log("Servicio", ServicioID, level);
  }, [ServicioID, level]); // Ejecutar la petición cuando ServicioID o level cambien

  useEffect(() => {
    //console.log("Admin", Admin);
  }, [Admin]);

  const handleChange = (event) => {
    const newStatus = event.target.value;
    onChange(newStatus); // Notificar al componente padre sobre el cambio
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={!!error}>
      <InputLabel id="motivo-select-label">Motivo</InputLabel>
      {loading ? (
        <CircularProgress />
      ) : (
        <Select
          labelId="motivo-select-label"
          id="motivo-select"
          value={MotivoID || ""} // Asegurarse de que MotivoID no sea undefined
          onChange={handleChange}
          label="Motivo"
          style={{ color: "#000000" }}
          readOnly={Admin}
        >
          {/* Opción por defecto "Sin seleccionar" */}
          <MenuItem value="">Sin seleccionar</MenuItem>

          {motivos.length === 0 ? ( // Verificar si no hay motivos
            <MenuItem value="" disabled>
              No hay motivos
            </MenuItem>
          ) : (
            motivos.map((motivo) => (
              <MenuItem key={motivo.StatusID} value={motivo.StatusID}>
                {motivo.Nombre}
              </MenuItem>
            ))
          )}
        </Select>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}{" "}
      {/* Muestra mensaje de error */}
    </FormControl>
  );
};

export default MotivosSelect;
