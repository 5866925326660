import React, { useEffect } from "react";

const TextInput = ({
  Gerente,
  id,
  label,
  value,
  color,
  register,
  required,
  pattern,
  maxLength,
  minLength,
  errors,
  validation,
  type = "text", // Tipo de input, por defecto es 'text'
}) => {
  // Función para convertir el texto ingresado a mayúsculas
  const handleInput = (e) => {
    if (type !== "password") {
      e.target.value = e.target.value.toUpperCase();
    }
  };

  useEffect(() => {
    //console.log(`Pattern para ${label}`, validation);
  }, [validation, label]);

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label} {required && <span style={{ color: "white" }}>*</span>}
      </label>
      <input
        style={{ background: color }}
        readOnly={Gerente}
        type={type}
        id={id}
        value={value}
        className="form-control"
        maxLength={type !== "password" ? maxLength : undefined}
        onInput={handleInput}
        {...register(id, {
          required: required && `${label} es requerido.`,
          pattern: validation && {
            value: validation.pattern.value,
            message: validation.pattern.message,
          },
          maxLength: type !== "password" &&
            maxLength && {
              value: maxLength,
              message: `${label} no puede exceder los ${maxLength} caracteres.`,
            },
          minLength: minLength && {
            value: minLength,
            message: `${label} debe tener al menos ${minLength} caracteres.`,
          },
        })}
      />
      {errors[id] && (
        <span style={{ color: "red" }}>
          <i className="fas fa-exclamation-triangle"></i> {errors[id].message}
        </span>
      )}
    </div>
  );
};

export default TextInput;
