import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

const ProveedorSelect = ({ Gerente, selectedServicio, Proveedor, required }) => {
  const [proveedores, setProveedores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme(); // Obtener el tema

  useEffect(() => {
    //console.log("EL servicio es:", selectedServicio);
    const fetchProveedores = async () => {
      try {
        const response = await axios.get("/clientes/Proveedores", {
          params: { servicio: selectedServicio },
        });
        setProveedores(response.data);
      } catch (err) {
        //console.error("Error fetching proveedores:", err);
        setError("Error al cargar proveedores.");
      } finally {
        setLoading(false);
      }
    };

    fetchProveedores();
  }, [selectedServicio]);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={Boolean(errors.proveedor)}
    >
      <InputLabel id="proveedor-label" style={{ color: "#000000" }}>
        Proveedor *
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Controller
          name="proveedor"
          control={control}
          defaultValue={Proveedor || ""}
          render={({ field }) => (
            <Select
            required={required}
              readOnly={Gerente}
              {...field}
              labelId="proveedor-label"
              id="proveedor"
              label="Proveedor"
              style={{
                background: theme.palette.background.default, // Color de fondo del Select
                color: "#000000", // Color del texto
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                  },
                },
              }}
              renderValue={(selected) => (
                <span style={{ color: "#000000" }}>
                  {selected === ""
                    ? "Seleccione un proveedor"
                    : proveedores.find(
                        (proveedor) => proveedor.ProveedorID === selected
                      )?.Nombre}
                </span>
              )}
            >
              <MenuItem value="" style={{ color: "#000000" }} disabled>
                Seleccione un proveedor
              </MenuItem>
              {proveedores.length > 0 ? (
                proveedores.map((proveedor) => (
                  <MenuItem
                    key={proveedor.ProveedorID}
                    value={proveedor.ProveedorID}
                    style={{ color: "#000000" }}
                  >
                    {proveedor.Nombre}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled style={{ color: "#000000" }}>
                  No hay proveedores disponibles
                </MenuItem>
              )}
            </Select>
          )}
        />
      )}
      <FormHelperText>{errors.proveedor?.message || ""}</FormHelperText>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ProveedorSelect;
