import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Loginstyles.css"; // Asegúrate de que este archivo contenga los estilos necesarios
import Logo2 from "../GrupoSirLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";

const BuscarAsesor = () => {
  const { register, handleSubmit } = useForm();
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [showInvalidDataMessage, setShowInvalidDataMessage] = useState(false);
  const [showValidDataMessage, setShowValidDataMessage] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      //console.log("Datos enviados:", data);

      // Enviar los datos al backend
      const response = await axios.post("/login/BuscarAsesor", data);

      // Verifica el contenido de la respuesta
      //console.log("Respuesta del servidor:", response.data);

      if (response.status === 200) {
        const { AsesorExiste } = response.data;

        if (AsesorExiste) {
          if (AsesorExiste) {
            //console.log("Asesor existe");
            //console.log("Redirigiendo a /NuevaContrasena");
            setShowValidDataMessage(true);

            setTimeout(() => {
              navigate("/NuevaContrasena");
              setShowValidDataMessage(true);
              setShowValidDataMessage(false);
            }, 3000); // Espera 3000 milisegundos antes de ejecutar la función
          }
        } else {
          //console.log("Asesor no encontrado");
          setShowInvalidDataMessage(true);
          setTimeout(() => setShowInvalidDataMessage(false), 3000);
        }
      } else {
        //console.log("Código de estado inesperado:", response.status);
        setShowInvalidDataMessage(true);
        setTimeout(() => setShowInvalidDataMessage(false), 3000);
      }
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
      setShowInvalidDataMessage(true);
      setTimeout(() => setShowInvalidDataMessage(false), 3000);
    }
  };

  const handleBlurEmail = (e) => {
    setEmailEmpty(e.target.value === "");
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src={Logo2} className="img-fluid" alt="Logo2" />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <div className="card p-4 shadow-sm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-row align-items-center mb-4">
                  <Tooltip title="Regresar" arrow>
                    <IconButton onClick={() => navigate("/")} className="me-2">
                      <ArrowBackIosIcon />
                    </IconButton>
                  </Tooltip>
                  <p className="lead fw-normal mb-0">Buscar Usuario</p>
                </div>
                <div className="form-outline mb-4 position-relative">
                  <label className="form-label" htmlFor="email">
                    Correo
                  </label>
                  <div className="d-flex align-items-center position-relative">
                    <input
                      type="email"
                      id="email"
                      className={`form-control form-control-lg ${
                        emailEmpty ? "is-invalid" : ""
                      }`}
                      placeholder="ejemplo@gmail.com"
                      {...register("email", { required: true })}
                      onBlur={handleBlurEmail}
                    />
                    <MailOutlineIcon className="position-absolute end-0 me-3 text-muted" />
                  </div>
                  {emailEmpty && (
                    <div className="invalid-feedback">
                      El campo de correo está vacío
                    </div>
                  )}
                </div>
                <div className="text-center text-lg-start mt-4 pt-2">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg d-flex align-items-center"
                  >
                    <SearchIcon className="me-2" /> Buscar Asesor
                  </button>
                </div>
              </form>
              {showValidDataMessage && (
                <div className="alert alert-success mt-3" role="alert">
                  Correo de recuperación enviado
                </div>
              )}
              {showInvalidDataMessage && (
                <div className="alert alert-danger mt-3" role="alert">
                  Asesor no encontrado
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuscarAsesor;
