import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextInput from "../TextInput";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Asegúrate
import ProgramaSelect from "../ProgramasSelect";
import LocalidadSelect from "../LocalidadSelect";
import CESISelect from "../CESISelect";

const MejoravitCampos = ({ selectedServicio, clienteID, theme, EsGerente }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [Programa, setPrograma] = useState([]);
  const [Localidad, setLocalidad] = useState([]);
  const [CESI, setCESI] = useState([]);
  const [foto, setFoto] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      if (clienteID) {
        try {
          const response = await axios.get(
            `/services/BuscarMejoravit/${clienteID}`,
            {
              withCredentials: true,
            }
          );
          //console.log("La respuesta es", response);

          setPrograma(response.data.ProgramaID);
          setLocalidad(response.data.MunicipioID);
          setCESI(response.data.CESI_Ingresado);
          setFoto(response.data.Foto);

          setMessage(response.data.Message || "");
          reset(response.data);
        } catch (error) {
          //console.error("Error al cargar los datos del cliente", error);
          setSubmitError("Hubo un error al cargar los datos del cliente.");
          setMessage("Algunos de los campos esta incorrecto o hace falta");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [clienteID, reset]);

  useEffect(() => {
    if (selectedServicio) {
      const fieldsToReset = [
        "programa",
        "localidad",
        "cesi",
        "correo",
        "Porcent",
        "fechaRecepcion",
        "envioExpedienteFecha",
        "fechaEntregaCESI",
        "fechaNOT",
        "firmaEntregaNot",
        "firmaContrato",
        "foto",
        "validacion",
        "solicitudDescarga",
        "descargaTarjeta",
        "fecha2doDeposito",
        "credito",
        "saldoTarjeta",
        "pagoCliente",
        "cobro35",
        "GastosAdmin",
      ];

      const resetSelective = () => {
        const resetValues = fieldsToReset.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});

        reset((prevValues) => ({ ...prevValues, ...resetValues }));
      };

      resetSelective();
    }
  }, [selectedServicio, reset]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const linkTo = `/Documentos/${clienteID}/${selectedServicio}`;

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    setSubmitError(null);
    //console.log(data);
    try {
      const response = await axios.post(
        `/services/GuardarMejoravit/${clienteID}`,
        data,
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      
      //console.error("Error al guardar los datos", error);
      setSubmitError("Hubo un error al guardar los datos. Inténtalo de nuevo.");
      setMessage(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const severity = submitError ? "error" : message ? "success" : "";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <hr style={{ margin: "20px 0", border: "2px solid #17202a" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexDirection={isSmallScreen ? "column" : "row"}
      >
        <h1>Datos de Mejoravit</h1>

        <Box>
          <Tooltip title="Subir documentos">
            <IconButton
              component={Link}
              to={linkTo}
              size="large" // Tamaño grande
              style={{
                color: "#1976d2", // Cambia el color del ícono si es necesario
                backgroundColor: "#e3f2fd", // Color de fondo para hacerlo más visible
                borderRadius: "50%", // Forma circular para mayor impacto visual
                padding: "16px", // Espaciado adicional para que el ícono sea más grande
              }}
            >
              <NoteAddIcon style={{ fontSize: 40 }} />{" "}
              {/* Ajusta el tamaño del ícono aquí */}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <ProgramaSelect
              required={true}
              Gerente={EsGerente}
              register={register}
              errors={errors}
              Nombre="programa"
              color={theme.palette.background.default}
              Letras={"#000000"}
              defaultValue={Programa}
            />
          </Box>

          <Box flex={1}>
            <LocalidadSelect
            required={true}
              Gerente={EsGerente}
              register={register}
              errors={errors}
              Nombre="Entidad"
              color={theme.palette.background.default}
              Letras={"#000000"}
              Localidad={Localidad}
            />
          </Box>

          <Box flex={1}>
            <CESISelect
            required={true}
              Gerente={EsGerente}
              register={register}
              errors={errors}
              Nombre="cesi"
              color={theme.palette.background.default}
              Letras={"#000000"}
              defaultValue={CESI}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Porcent"
              label="%"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El porcentaje debe ser un número válido",
                },
              }}
              maxLength={100}
              errors={errors}
              fullWidth
            />
          </Box>

          {/* Campos de Fecha */}
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaRecepcion"
              label="Fecha de Recepción"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="envioExpedienteFecha"
              label="Envío Expediente Fecha"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaEntregaCESI"
              label="Fecha Entrega CESI"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaNOT"
              label="Fecha Notificacion"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="firmaEntregaNot"
              label="Firma Entrega Notificacion"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="firmaContrato"
              label="Firma Contrato"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>

          {/* Selección de Foto */}
          <Box flex={1}>
            <FormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={!!errors.foto}
            >
              <InputLabel id="foto-label" style={{ color: "#000000" }}>
                Foto *
              </InputLabel>
              <Select
                required
                readOnly={EsGerente}
                id="foto"
                labelId="foto-label"
                defaultValue={foto || ""}
                {...register("foto")}
                style={{
                  background: theme.palette.background.default, // Usa el color de fondo del tema
                  color: "#000000", // Color del texto
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                    },
                  },
                }}
                renderValue={(selected) => (
                  <span style={{ color: "#000000" }}>
                    {selected === ""
                      ? "Seleccione una opción"
                      : selected === 1
                      ? "Sí"
                      : "No"}
                  </span>
                )}
              >
                <MenuItem value="" style={{ color: "#000000" }} disabled>
                  Seleccione una opción
                </MenuItem>
                <MenuItem value={1} style={{ color: "#000000" }}>
                  Sí
                </MenuItem>
                <MenuItem value={2} style={{ color: "#000000" }}>
                  No
                </MenuItem>
              </Select>
              <FormHelperText>{errors.foto?.message || ""}</FormHelperText>
            </FormControl>
          </Box>

          {/* Más campos */}
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="validacion"
              label="Validación"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="solicitudDescarga"
              label="Solicitud de Descarga"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="descargaTarjeta"
              label="Descarga Tarjeta"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fecha2doDeposito"
              label="Fecha de 2do Depósito"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="text"
              id="correo"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, // Validación básica de formato de correo electrónico
                  message: "El correo electrónico debe ser válido.",
                },
              }}
              label="Correo"
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Formato Contabilidad
        </Typography>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="credito"
              label="Crédito"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El crédito debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="saldoTarjeta"
              label="Saldo Tarjeta"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El saldo debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="pagoCliente"
              label="Pago Cliente 20%"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El pago debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="cobro35"
              label="Cobro 35%"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El cobro debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>

          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="ContrasenaCuentaInfonavit"
              label="Contraseña Cuenta Infonavit"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="CorreccionRFC"
              label="Corrección RFC"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="ActaNacimiento"
              label="Acta de Nacimiento"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="RFC"
              label="RFC"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Taller"
              label="Taller"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="ComprobanteDomicilio"
              label="Comprobante de domicilio"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Adelanto"
              label="Adelanto"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El adelanto debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="GastosAdmin"
              label="Gastos Administrativos"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message:
                    "Los gastos administrativos debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="SegundoDeposito"
              label="Segundo Deposito"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="MontoDescarga"
              label="Monto de descarga"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El monto de descarga debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box textAlign="center" mt={3}>
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "#ffffff",
            }}
            fullWidth={isSmallScreen}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar"
            )}
          </Button>

          {message && (
            <div className={`alert alert-${severity}`} role="alert">
              {message}
            </div>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default MejoravitCampos;
