import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextInput from "../TextInput"; // Ajusta la ruta si es necesario
import ProveedorSelect from "../ProveedorSelect";
import {
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios"; // Asegúrate de instalar axios
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Asegúrate

const InfonavitCampos = ({ selectedServicio, clienteID, theme, EsGerente }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [proveedor, setProveedor] = useState("");

  // Estado de respuesta
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      if (clienteID) {
        try {
          const response = await axios.get(
            `/services/BuscarInfonavit/${clienteID}`,
            {
              withCredentials: true,
            }
          );

          //console.log(response);
          setProveedor(response.data.Proveedor?.ProveedorID || "");
          setMessage(response.data.Message || "");
          //console.log("Respuesta", response);
          reset(response.data);
        } catch (error) {
          //console.error("Error al cargar los datos del cliente", error);
          setSubmitError("Hubo un error al cargar los datos del cliente.");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [clienteID, reset]);

  useEffect(() => {
    if (selectedServicio) {
      const fieldsToReset = [
        "porcentaje",
        "saldo",
        "edad",
        "claveElector",
        "fechaEnvio",
        "finTramite",
      ];

      const resetSelective = () => {
        const resetValues = fieldsToReset.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});

        reset((prevValues) => ({ ...prevValues, ...resetValues }));
      };

      resetSelective();
    }
  }, [selectedServicio, reset]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const linkTo = `/Documentos/${clienteID}/${selectedServicio}`;

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    setSubmitError(null);

    try {
      const response = await axios.post(
        `/services/GuardarInfonavit/${clienteID}`,
        data,
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      //console.error("Error al guardar los datos", error);
      setSubmitError("Hubo un error al guardar los datos. Inténtalo de nuevo.");
      setMessage("Algunos de los campos esta incorrecto o hace falta");
    } finally {
      setSubmitLoading(false);
    }
  };

  // Determina el tipo de mensaje a mostrar
  const severity = submitError ? "error" : message ? "success" : "";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <hr style={{ margin: "20px 0", border: "2px solid #17202a" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexDirection={isSmallScreen ? "column" : "row"}
      >
        <h1>Datos de Infonavit</h1>
        <Box>
          <Tooltip title="Subir documentos">
            <IconButton
              component={Link}
              to={linkTo}
              size="large" // Tamaño grande
              style={{
                color: "#1976d2", // Cambia el color del ícono si es necesario
                backgroundColor: "#e3f2fd", // Color de fondo para hacerlo más visible
                borderRadius: "50%", // Forma circular para mayor impacto visual
                padding: "16px", // Espaciado adicional para que el ícono sea más grande
              }}
            >
              <NoteAddIcon style={{ fontSize: 40 }} />{" "}
              {/* Ajusta el tamaño del ícono aquí */}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {/* Contenido del formulario */}
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Primera fila: Porcentaje, Saldo, Edad */}
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Porcentaje"
              label="Porcentaje"
              register={register}
              color={theme.palette.background.default}
              type="text"
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El porcentaje debe ser un número válido.",
                },
              }}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Saldo"
              label="Saldo"
              register={register}
              color={theme.palette.background.default}
              type="text"
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El saldo debe ser un número válido.",
                },
              }}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Edad"
              label="Edad"
              register={register}
              color={theme.palette.background.default}
              type="text"
              validation={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: "La edad debe ser un número entero.",
                },
              }}
              errors={errors}
            />
          </Box>
        </Box>

        {/* Segunda fila: Clave Elector, Proveedor, Fecha de Envío, Fin del Trámite */}
        <Box
          display="flex"
          gap={2}
          flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="claveElector"
              label="Clave de Elector"
              register={register}
              color="white" // o tu tema, como theme.palette.background.default
              maxLength={18}
              validation={{
                pattern: {
                  value: /^[A-Z0-9]+$/,
                  message:
                    "La Clave de Elector solo debe tener numeros y letras.",
                },
              }}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <ProveedorSelect
              Gerente={EsGerente}
              selectedServicio={selectedServicio}
              Proveedor={proveedor}
              color={theme.palette.background.default}
              Letras={"#000000"}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="FechaEnvio"
              label="Fecha de Envío"
              register={register}
              color={theme.palette.background.default}
              type="date"
              errors={errors}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
        >
          <Box flex={1}>
            <TextInput
              id="finTramite"
              label="Fin del Trámite"
              register={register}
              color={theme.palette.background.default}
              type="date"
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              id="Contrasena"
              label="Contraseña"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
        </Box>
        {/* Botón de Enviar */}
        <Box textAlign="center" mt={3}>
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "#ffffff",
            }}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar"
            )}
          </Button>
          {message && (
            <div className={`alert alert-${severity}`} role="alert">
              {message}
            </div>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default InfonavitCampos;
