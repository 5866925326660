import React, { useState, useEffect } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  CircularProgress,
  Grid, 
  Button,
  Snackbar,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

const LocationForm = ({
  Gerente,
  initialCodigoPostal,
  initialMunicipioID,
  initialLocalidadID,
  theme,
  onLocationChange,
  required
}) => {
  const { control, register, setValue } = useForm();
  const [estados, setEstados] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState("");
  const [codigoPostal, setCodigoPostal] = useState(initialCodigoPostal || "");
  const [localidades, setLocalidades] = useState([]);
  const [selectedLocalidad, setSelectedLocalidad] = useState("");
  const [loadingLocalidades, setLoadingLocalidades] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch estados and determine the selected state
  useEffect(() => {
    const fetchEstados = async () => {
      try {
        const response = await axios.get("/Direcciones/estados");
        setEstados(response.data);
      } catch (error) {
        //console.error("Error fetching estados:", error);
      }
    };

    const encontrarEstadoSeleccionado = async () => {
      if (initialCodigoPostal && initialLocalidadID) {
        try {
          const response = await axios.get(
            `/Direcciones/BuscarEstado/${initialMunicipioID}/${initialLocalidadID}/${initialCodigoPostal}`
          );
          setSelectedEstado(response.data.EstadoID);
        } catch (error) {
          //console.error("Error fetching estado seleccionado:", error);
        }
      }
    };

    fetchEstados();
    encontrarEstadoSeleccionado();
  }, [initialCodigoPostal, initialLocalidadID, initialMunicipioID]);

  // Fetch localidades when estado or codigoPostal changes
  useEffect(() => {
    const fetchLocalidades = async () => {
      if (!selectedEstado || codigoPostal.length !== 5) return;

      setLoadingLocalidades(true);
      try {
        const response = await axios.get(
          `/Direcciones/localidades/${selectedEstado}/${codigoPostal}`
        );
        if (response.status === 200 || response.status === 202) {
          setLocalidades(response.data);
          // Only set selectedLocalidad if it exists in the fetched localidades
          if (
            response.data.some(
              (localidad) => localidad.LocalidadID === initialLocalidadID
            )
          ) {
            setSelectedLocalidad(initialLocalidadID);
            setValue("localidad", initialLocalidadID);
          } else {
            setSelectedLocalidad(""); // Clear if the localidad isn't found
            setValue("localidad", "");
          }
        } else {
          setErrorMessage(
            "No se encontró ninguna localidad para los valores proporcionados."
          );
          setOpenSnackbar(true);
        }
      } catch (error) {
        //console.error("Error fetching localidades:", error);
        setErrorMessage("Error al buscar localidades.");
        setOpenSnackbar(true);
      } finally {
        setLoadingLocalidades(false);
      }
    };

    fetchLocalidades();
  }, [selectedEstado, codigoPostal, initialLocalidadID, setValue]);

  const handleSearchLocalidades = async () => {
    if (!selectedEstado || codigoPostal.length !== 5) {
      setErrorMessage(
        "Por favor, selecciona un estado y un código postal válido."
      );
      setOpenSnackbar(true);
      return;
    }

    setLoadingLocalidades(true);
    try {
      const response = await axios.get(
        `/Direcciones/localidades/${selectedEstado}/${codigoPostal}`
      );
      if (response.status === 200 || response.status === 202) {
        setLocalidades(response.data);
      } else {
        setErrorMessage(
          "No se encontró ninguna localidad para los valores proporcionados."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      //console.error("Error fetching localidades:", error);
      setErrorMessage("Error al buscar localidades.");
      setOpenSnackbar(true);
    } finally {
      setLoadingLocalidades(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Clean localidades and selected localidad when changing estado or postal code
  const handleEstadoChange = (e) => {
    const value = e.target.value;
    setSelectedEstado(value);
    setLocalidades([]); // Clear localidades when estado changes
    setSelectedLocalidad(""); // Clear selected localidad
  };

  const handleCodigoPostalChange = (e) => {
    setCodigoPostal(e.target.value);
    setValue("codigoPostal", e.target.value);
    setLocalidades([]); // Clear localidades when codigoPostal changes
    setSelectedLocalidad(""); // Clear selected localidad
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* Select for Estado */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="filled" margin="normal">
            <InputLabel sx={{ color: "black" }}>Estado *</InputLabel>
            <Controller
              name="estado"
              control={control}
              render={({ field }) => (
                <Select
                required={required}
                  readOnly={Gerente}
                  {...field}
                  value={selectedEstado}
                  onChange={handleEstadoChange}
                  sx={{
                    bgcolor: theme.palette.background.default,
                    color: "black",
                  }}
                  aria-label="Seleccione un estado" // Accessibility
                >
                  {estados.map((estado) => (
                    <MenuItem
                      key={estado.EstadoID}
                      value={estado.EstadoID}
                      sx={{ color: "black" }}
                    >
                      {estado.Nombre}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        {/* TextField for Codigo Postal */}
        <Grid item xs={12} sm={3}>
          <TextField
           required={required}
            readOnly={Gerente}
            label="Código Postal *"
            {...register("codigoPostal", { required: required })}
            value={codigoPostal}
            onChange={handleCodigoPostalChange}
            fullWidth
            variant="filled"
            margin="normal"
            inputProps={{ maxLength: 5 }}
            sx={{ bgcolor: theme.palette.background.default, color: "black" }}
            InputLabelProps={{ style: { color: "black" } }}
            InputProps={{ style: { color: "black" } }}
            aria-label="Código postal" // Accessibility
          />
        </Grid>

        {/* Button for Search */}
        <Grid item xs={12} sm={3}>
          <Button
            readOnly={Gerente}
            fullWidth
            variant="filled"
            margin="normal"
            onClick={handleSearchLocalidades}
            sx={{
              bgcolor: theme.palette.secondary.main, // Usa el mismo color que los otros elementos
              color: "white", // Color del texto para que resalte en el botón
              "&:hover": {
                bgcolor: theme.palette.secondary.dark, // Cambia el color al pasar el mouse
              },
              // Asegúrate de que el padding y borderRadius sean consistentes con los demás componentes
              padding: "12px 16px",
              borderRadius: "4px",
            }}
            aria-label="Buscar localidades" // Accessibility
          >
            Buscar Localidades
          </Button>
        </Grid>

        {/* Select for Localidad */}
        <Grid item xs={12} sm={3}>
          <FormControl
            fullWidth
            variant="filled"
            margin="normal"
            disabled={loadingLocalidades || localidades.length === 0}
          >
            <InputLabel sx={{ color: "black" }}>Localidad *</InputLabel>
            {loadingLocalidades ? (
              <CircularProgress size={24} />
            ) : (
              <Controller
                name="localidad"
                control={control}
                render={({ field }) => (
                  <Select
                  required={required}
                    readOnly={Gerente}
                    {...field}
                    value={selectedLocalidad || ""} // Ensure a fallback value is provided
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedLocalidad(value);
                      field.onChange(value);
                      onLocationChange({
                        estado: selectedEstado,
                        codigoPostal,
                        localidad: value,
                      });
                    }}
                    sx={{
                      bgcolor: theme.palette.background.default,
                      color: "black",
                    }}
                  >
                    {localidades.map((localidad) => (
                      <MenuItem
                        key={localidad.LocalidadID}
                        value={localidad.LocalidadID}
                        sx={{ color: "black" }}
                      >
                        {localidad.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
          </FormControl>
        </Grid>
      </Grid>

      {/* Snackbar for error messages */}
      <Snackbar
        open={openSnackbar}
        message={errorMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        aria-live="assertive" // Important message
        role="alert" // Accessibility
      />
    </Box>
  );
};

export default LocationForm;
