import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import axios from "axios";

const ProgramaSelect = ({
  Gerente,
  register,
  errors,
  Nombre,
  defaultValue = "",
  color,
  Letras,
  required
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgramas = async () => {
      try {
        const response = await axios.get("/clientes/Programas");
        setOptions(response.data);
      } catch (err) {
        //console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProgramas();
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={!!errors[Nombre]}
    >
      <InputLabel
        style={{ color: Letras, fontSize: 20 }}
        id={`${Nombre}-label`}
      >
        Programa *
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <FormHelperText>Hubo un error al cargar los programas.</FormHelperText>
      ) : (
        <Select
          
          readOnly={Gerente}
          style={{ background: color, color: "#000000" }} // Estilo aplicado al Select
          labelId={`${Nombre}-label`}
          id={Nombre}
          defaultValue={defaultValue || ""} // Establecer el valor predeterminado si no está definido
          {...register(Nombre, { required:required})}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: color, // Fondo del menú desplegable
              },
            },
          }}
          renderValue={(selected) => (
            <span style={{ color: "#000000" }}>
              {options.find((option) => option.ArticuloID === selected)
                ?.Nombre || "Seleccione una opción"}
            </span>
          )}
        >
          <MenuItem style={{ color: "#000000" }} value="">
            <em>Seleccione una opción</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.ArticuloID}
              value={option.ArticuloID}
              style={{ color: "#000000" }} // Color para el texto de las opciones
            >
              {option.Nombre}
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText>{errors[Nombre]?.message || ""}</FormHelperText>
    </FormControl>
  );
};

export default ProgramaSelect;
