import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  TextField,
  Chip,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import axios from "axios";
import StatusSelect from "../components/StatusSelect";
import MotivosSelect from "../components/MotivosSelect";
import ExportButton from "../components/ExportButton";
import NotificacionAsesorModal from "../NotificacionAsesorModal";

const ClientesTable = () => {
  const [clientes, setClientes] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Nombre");
  const [searchTerm, setSearchTerm] = useState("");
  const [openRows, setOpenRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [niveles, setNiveles] = useState({});
  const [motivos, setMotivos] = useState({});
  const [administrador, setAdministrador] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientes = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/clientes/AllClients", {
          withCredentials: true,
        });
        setClientes(response.data);
        console.log("clientes", response.data);

        // Initialize niveles state with NivelID for each registroID
        const nivelesData = {};
        response.data.forEach((cliente) => {
          nivelesData[cliente.registroID] = cliente.NivelID;
        });
        setNiveles(nivelesData);
        //console.log("nivelesData:", nivelesData);
        const motivoData = {};
        response.data.forEach((cliente) => {
          motivoData[cliente.registroID] = cliente.StatusID;
          /*console.log(
            `RegistroID: ${cliente.registroID}, StatusID: ${cliente.StatusID}`
          );*/
        });
        setMotivos(motivoData);
        //console.log("motivoData:", motivoData);
      } catch (error) {
        //console.error("Error al cargar clientes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get("/select/admin", {
          withCredentials: true, // Asegúrate de que esto esté correctamente configurado
        });

        // Guardar el administrador en el estado del componente
        setAdministrador(response.data.Permisos);
        //console.log(administrador);
        // Aquí puedes manejar la respuesta
        //console.log("Admin", response.data.Permisos); // Por ejemplo, imprimir los datos en la consola
      } catch (error) {
        //console.error('Error fetching admin data:', error);
      }
    };

    fetchAdminData();
  }, [administrador]); // Dependencias vacías para que se ejecute solo una vez al montar el componente

  const handleStatusChange = async (
    newStatus,
    registroID,
    clienteID,
    servicioID
  ) => {
    //console.log("Status changed to:", newStatus);
    //console.log("Record details:", clienteID, servicioID);

    // Actualizar la UI inmediatamente
    setNiveles((prevNiveles) => ({
      ...prevNiveles,
      [registroID]: newStatus,
    }));

    try {
      await axios.post(
        `/clientes/CambiarStatus/${clienteID}/${servicioID}`,
        { nivel: newStatus },
        { withCredentials: true }
      );

      //console.log("Status cambiado exitosamente:", response.data);

      // Llamar a handleMotivoChange con null si es necesario
      handleMotivoChange(null, registroID, clienteID, servicioID);
    } catch (error) {
      //console.error("Error al cambiar el status:", error);

      // Revertir al estado anterior si es necesario
      setNiveles((prevNiveles) => ({
        ...prevNiveles,
        [registroID]: servicioID === 6 ? 1 : prevNiveles[registroID],
      }));
    }
  };

  const handleMotivoChange = async (
    newMotivo,
    registroID,
    clienteID,
    servicioID
  ) => {
    //console.log("Motivo changed to:", newMotivo);
    //console.log("Record details:", clienteID, servicioID);

    const prevMotivo = motivos[registroID];

    // Actualizar la UI inmediatamente
    setMotivos((prevMotivos) => ({
      ...prevMotivos,
      [registroID]: newMotivo,
    }));

    try {
      await axios.post(
        `/clientes/CambiarMotivo/${clienteID}/${servicioID}`,
        { motivo: newMotivo },
        { withCredentials: true }
      );
      //console.log("Motivo cambiado exitosamente:", response.data);
    } catch (error) {
      //console.error("Error al cambiar el motivo:", error);

      // Revertir el estado si falla
      setMotivos((prevMotivos) => ({
        ...prevMotivos,
        [registroID]: prevMotivo,
      }));
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (ClienteID) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [ClienteID]: !prevOpenRows[ClienteID],
    }));
  };

  const handleEditClick = (ClienteID, ServicioID) => {
    navigate(`/NuevoCliente/${ClienteID}/${ServicioID}`);
  };

  const getChipColor = (servicioNombre) => {
    switch (servicioNombre) {
      case "Prospecto":
        return teal[900];
      case "Mejoravit":
        return "#FB8C00";
      case "Infonavit":
        return "#5C6BC0";
      case "Linea2":
        return "#673AB7";
      case "Embarazadas":
        return "#EC407A";
      default:
        return "default";
    }
  };

  const filteredClientes = clientes.filter((cliente) =>
    [
      cliente.Nombres,
      cliente.ApellidoPaterno,
      cliente.ApellidoMaterno,
      cliente.NSS,
      cliente.CURP,
      cliente.ServicioNombre,
      cliente.StatusNombre,
    ].some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedClientes = filteredClientes.sort((a, b) => {
    const getComparator = (order, orderBy) => (a, b) => {
      if (orderBy === "Nombre") {
        return order === "asc"
          ? a.Nombres.localeCompare(b.Nombres)
          : b.Nombres.localeCompare(a.Nombres);
      }
      if (orderBy === "Apellido Paterno") {
        return order === "asc"
          ? a.ApellidoPaterno.localeCompare(b.ApellidoPaterno)
          : b.ApellidoPaterno.localeCompare(a.ApellidoPaterno);
      }
      if (orderBy === "Apellido Materno") {
        return order === "asc"
          ? a.ApellidoMaterno.localeCompare(b.ApellidoMaterno)
          : b.ApellidoMaterno.localeCompare(a.ApellidoMaterno);
      }
      if (orderBy === "CURP") {
        return order === "asc"
          ? a.CURP.localeCompare(b.CURP)
          : b.CURP.localeCompare(a.CURP);
      }
      if (orderBy === "NSS") {
        return order === "asc"
          ? a.NSS.localeCompare(b.NSS)
          : b.NSS.localeCompare(a.NSS);
      }
      return 0;
    };
    return getComparator(order, orderBy)(a, b);
  });

  const [clienteToNotify, setClienteToNotify] = useState(null); // Estado para almacenar los datos del cliente

  // Función para abrir el modal y pasar los datos del cliente
  const handleNotificar = (cliente) => {
    setClienteToNotify(cliente); // Asigna los datos del cliente al estado
    setIsModalOpen(true); // Abre el modal
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Función para enviar la notificación
  const handleSendNotification = async (clienteId, servicioId, asesorID, mensaje) => {
    console.log("Notificación enviada:", { clienteId, servicioId, asesorID, mensaje });
    try {
      const response = await axios.post("/select/Notificacion", {
        clienteId,
        servicioId,
        asesorID,
        mensaje,
      });
      console.log("Respuesta del servidor:", response.data);
      return response.data.message;
    } catch (error) {
      console.error("Error al enviar la notificación:", error);
    }
  };

  return (
    <div>
      <Menu />
      <div className="bg-beige container-wrapper">
        <div
          style={{
            paddingBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/NuevoCliente"
          >
            Nuevo Cliente
          </Button>

          <div
            style={{
              marginLeft: "auto", // Empuja el botón de exportar a la derecha
              display: "flex", // Asegúrate de que el contenedor pueda usar flexbox
              alignItems: "center",
            }}
          >
            <ExportButton
              tableData={sortedClientes}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} />
                  {[
                    "Nombre",
                    "Apellido Paterno",
                    "Apellido Materno",
                    "CURP",
                    "NSS",
                    "Servicio",
                    "Status",
                    "Motivo",
                    "Accion",
                  ].map((headCell) => (
                    <TableCell
                      key={headCell}
                      sortDirection={orderBy === headCell ? order : false}
                      onClick={() => handleRequestSort(headCell)}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                        textAlign: "center",
                      }}
                    >
                      {headCell}
                      {orderBy === headCell ? (
                        order === "asc" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedClientes.length > 0 ? (
                  sortedClientes.map((cliente) => (
                    <React.Fragment key={cliente.registroID}>
                      <TableRow hover style={{ backgroundColor: "#fafafa" }}>
                        <TableCell style={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleRowClick(cliente.ClienteID)}
                          >
                            {openRows[cliente.ClienteID] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.Nombres}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.ApellidoPaterno}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.ApellidoMaterno}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.CURP}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.NSS}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Chip
                            label={cliente.ServicioNombre}
                            style={{
                              backgroundColor: getChipColor(
                                cliente.ServicioNombre
                              ),
                              color: "white",
                              fontWeight: "bold",
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <StatusSelect
                            Admin={administrador}
                            Nivel={niveles[cliente.registroID] || ""} // Obtener NivelID del estado niveles
                            onChange={(newStatus) => {
                              handleStatusChange(
                                newStatus,
                                cliente.registroID, // Cambiar esto a registroID
                                cliente.ClienteID,
                                cliente.ServicioID
                              );
                            }}
                          />
                        </TableCell>

                        <TableCell style={{ textAlign: "center" }}>
                          <MotivosSelect
                            ServicioID={cliente.ServicioID}
                            MotivoID={motivos[cliente.registroID] || ""} // Obtener Motivo del estado motivos
                            level={niveles[cliente.registroID] || ""} // Obtener nivel del estado niveles
                            onChange={(newMotivo) => {
                              handleMotivoChange(
                                newMotivo,
                                cliente.registroID, // Asegúrate de que registroID está presente y es el correcto
                                cliente.ClienteID,
                                cliente.ServicioID
                              );
                            }}
                            Admin={administrador}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "8px",
                            }}
                          >
                            <Tooltip title="Editar Cliente" arrow>
                              <IconButton
                                onClick={() =>
                                  handleEditClick(
                                    cliente.ClienteID,
                                    cliente.ServicioID
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Notificar a asesor" arrow>
                              <IconButton
                                onClick={() =>
                                  handleNotificar(cliente)
                                }
                              >
                                <NotificationsIcon />
                              </IconButton>
                            </Tooltip>
                          </div>

                          <NotificacionAsesorModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSend={handleSendNotification}
        clienteId={clienteToNotify?.ClienteID} // Pasamos los datos del cliente al modal
        servicioId={clienteToNotify?.ServicioID}
        asesorID={clienteToNotify?.AsesorID}
        // Aquí puedes agregar un campo para el mensaje si lo deseas
      />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={8}
                        >
                          <Collapse
                            in={openRows[cliente.ClienteID]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              sx={{
                                padding: 3,
                                backgroundColor: "#f5f5f5",
                                borderRadius: 2,
                              }}
                            >
                              <Typography
                                variant="h5"
                                gutterBottom
                                color="primary"
                              >
                                Detalles del Cliente
                              </Typography>

                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Comentarios:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {cliente.Comentarios}
                                </Typography>
                              </Box>

                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Modificado por:
                                </Typography>
                              </Box>
                              <Typography variant="body1" color="textSecondary">
                                {cliente.Asesor.Nombres}{" "}
                                {cliente.Asesor.ApellidoPaterno}{" "}
                                {cliente.Asesor.ApellidoMaterno}
                              </Typography>
                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Creado el:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {cliente.createdAt}
                                </Typography>
                              </Box>

                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Actualizado el:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {cliente.updatedAt}
                                </Typography>
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      No se encontraron resultados.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ClientesTable;
