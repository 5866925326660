import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./login";
import Menu from "./components/Menu";
import NuevoUsuario from "./Administrador/Elementos/tablasAdmin/Usuarios/NuevoUsuario";
import TabsAdmin from "../src/Administrador/Elementos/TabsAdmin";
import Confirmacion from "./Administrador/Elementos/tablasAdmin/Usuarios/Confirmacion";
import Oficinas from "./Administrador/Elementos/tablasAdmin/Oficinas/OficinasNueva";
import Clientes from "./Asesores/TablaClientes";
import BuscarAsesor from "./Asesores/BuscarAsesor";
import NuevaContrasena from "./Asesores/NuevaContasena";
import NuevoCliente from "./Asesores/NuevoCliente";
import Documentos from "./components/Servicios/Documentos";
import Dashboard from "./Dashboard";
const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Menu" element={<ProtectedRoute element={Menu} />} />
      <Route
        path="/NuevoUsuario"
        element={<ProtectedRoute element={NuevoUsuario} />}
      />
      <Route
        path="/Usuarios"
        element={<ProtectedRoute element={TabsAdmin} />}
      />
      <Route
        path="/Confirmacion"
        element={<ProtectedRoute element={Confirmacion} />}
      />
      <Route path="/Oficinas" element={<ProtectedRoute element={Oficinas} />} />
      <Route path="/Clientes" element={<ProtectedRoute element={Clientes} />} />
      <Route path="/BuscarAsesor" element={<BuscarAsesor />} />
      <Route path="/NuevaContrasena" element={<NuevaContrasena />} />
      <Route path="/NuevaContrasena/:token" element={<NuevaContrasena />} />

      <Route
        path="/NuevoCliente"
        element={<ProtectedRoute element={NuevoCliente} />}
      />
      <Route
        path="/NuevoCliente/:ClienteID/:ServicioID"
        element={<ProtectedRoute element={NuevoCliente} />}
      />
      <Route
        path="/Documentos/:ClienteID/:ServicioID"
        element={<ProtectedRoute element={Documentos} />}
      />
      <Route
        path="/Dashboard"
        element={<ProtectedRoute element={Dashboard} />}
      />
    </Routes>
  );
};

export default RouterConfig;
