// App.js
import React from "react";
import { Helmet } from "react-helmet";
import RouterConfig from "./RouterConfig";


const App = () => {
  return (
    <div>
      <Helmet>
        <title>GrupoSIR</title>
        <link rel="icon" href="../public/favicon.ico" /> {/* Ruta al favicon */}
      </Helmet>
      <RouterConfig />
    </div>
  );
};

export default App;
