import React from "react";
import { Typography, Box } from "@mui/material";
import Menu from "./components/Menu"; // Asegúrate de que la ruta sea correcta
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Gráficas
import PorProcesar from "./components/Graficas/PorProcesar"; // Asegúrate de que la ruta sea correcta
import Detenidos from "./components/Graficas/Detenidos";
//import Saldo from "./components/Graficas/SaldoOficina";

function Dashboard() {
  return (
    <>
      <header>
        <Menu />
      </header>
      <Box
        sx={{ padding: "20px", backgroundColor: "#f4f4f4", minHeight: "100vh" }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center" // Usar align para centrar el texto
          sx={{ marginBottom: "20px", color: "#333" }} // Usar sx para el estilo
        >
          Información
        </Typography>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <PorProcesar /> {/* Usar el componente PorProcesar */}
            </div>
            <div className="col-md-6 mb-4">
              <Detenidos /> {/* Usar el componente Detenidos */}
            </div>
           {/* <div className="col-md-6 mb-4">
              <Saldo /> 
            </div>*/}
          </div>
        </div>
      </Box>
    </>
  );
}

export default Dashboard;
